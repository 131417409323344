import fetchCall from './ComunicationService';

const ContractService = () => {
  const getContracts = () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('contracts/all', {}, headers, 'GET', {})
      .then((response) => response.contracts)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const getContract = (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`contracts/contract/${id}`, {}, headers, 'GET', {})
      .then((response) => response.contract)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const saveAnswers = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(
        `contracts/contract/${data.questionaryId}`,
        {},
        headers,
        'PUT',
        data
      )
      .then((response) => response.contract)
      .catch((error) => {
        throw new error();
      });
  };

  const getContractMessages = (contract) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`contracts/messages/${contract}`, {}, headers, 'GET', {})
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const addContractMessage = (data) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http(`contracts/messages/${data.contractId}`, {}, headers, 'POST', data)
      .then((response) => response.messages)
      .catch((error) => {
        throw new error();
      });
  };

  return {
    getContract,
    getContracts,
    getContractMessages,
    addContractMessage,
    saveAnswers,
  };
};

export default ContractService();
