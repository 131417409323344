const schema = {
  email: {
    presence: {
      allowEmpty: false,
      message: 'el email es requerido',
      fullMessages: false,
    },
    email: true,
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'es requerido',
      fullMessages: false,
    },
  },
};

export default schema;
