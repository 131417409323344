import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import "./cards.css";

function Cards(props) {
  const { contractId, service, serviceType, allowMessage, showReservio } =
    props;
  const [showModal, setShowModal] = useState(false);

  const renderDocuments = () => {
    if (serviceType === "streaming") {
      if (!service.documents || service.documents.length === 0) {
        return (
          <div className="important-message">
            <span>El documento se habilitará una vez terminado el taller</span>
            <br />
          </div>
        );
      }

      return service.documents.map((doc) => (
        <>
          <a
            href={`https://storage.googleapis.com/mimate-services/${doc}`}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar documento adjunto
          </a>
        </>
      ));
    }

    if (serviceType === "video") {
      return service.documents.map((doc) => (
        <>
          <a
            href={`https://storage.googleapis.com/mimate-services/${doc}`}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar documento adjunto
          </a>
        </>
      ));
    }
  };

  const renderVideo = () => {
    const allowServices = ["streaming", "video", "professional"];
    if (!allowServices.includes(serviceType)) {
      return;
    }

    if (service.show_video === false) {
      return;
    }

    if (serviceType === "professional" && !service.intro_video) {
      return;
    }
    if (!service.video && !serviceType === "professional") {
      return (
        <div className="important-message">
          <span>La grabación del taller se habilitará en 24/48 horas</span>
          <br />
        </div>
      );
    }

    return (
      <>
        <Link
          className="button"
          to={{
            pathname: "video",
            state: {
              videoUrl: serviceType === "professional"
                ? service.intro_video
                : service.video,
            },
          }}
        >
          Ver video
        </Link>
      </>
    );
  };
  const renderCard = () => {
    return (
      <>
        <div className="box-card box-full">
          <img
            className="service-image"
            src={service.image_cover}
            alt="servicio"
          />
          <div className="card-text-wrapper-full">
            <h1 className="card-title-full">{service.name}</h1>
            {service.streaming_date && (
              <h2 className="card-subtitle-full">
                {new Intl.DateTimeFormat("es-ES").format(
                  new Date(service.streaming_date),
                )} - {service.streaming_hour}
              </h2>
            )}
            <p className="summary-full">{service.summary}</p>
            <br />
            {renderDocuments()}
            {renderVideo()}
            {(serviceType === "streaming" ||
              serviceType === "video" ||
              (serviceType === "professional" && allowMessage)) && (
              <Link
                className="button"
                to={{
                  pathname: `message/${contractId}`,
                  state: {
                    serviceId: service.service_id,
                    contractId: contractId,
                    serviceName: service.name,
                    serviceType: serviceType,
                    allowNewQuestion:
                      (serviceType === "professional" && allowMessage) ||
                        service.video
                        ? true
                        : false,
                    service,
                  },
                }}
              >
                Chat
              </Link>
            )}
            <div className="buttons-container">
              {service.zoom_info && (
                <button
                  className="button"
                  onClick={() => setShowModal(true)}
                >
                  Click para acceder al taller
                </button>
              )}
              {service.initialQuestions && (
                <Link
                  className="button"
                  to={{
                    pathname: `questionary/${contractId}`,
                    state: {
                      serviceId: service.service_id,
                      contractId: contractId,
                      serviceName: service.name,
                      questions: service.initialQuestions,
                    },
                  }}
                >
                  Cuestionario Inicial
                </Link>
              )}
            </div>
          </div>
        </div>
        <Modal show={showModal} setShowModal={setShowModal}>
          <div
            dangerouslySetInnerHTML={{
              __html: service && service.zoom_info,
            }}
          >
          </div>
        </Modal>
      </>
    );
  };
  return <>{renderCard()}</>;
}

export default Cards;
