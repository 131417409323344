import React from 'react';
import { useLocation } from 'react-router-dom';
import './video.css';

const Video = (props) => {
  const location = useLocation();
  return (
    <video className="video-player" controls preload="auto">
      <source src={location.state.videoUrl} type="video/mp4" />
    </video>
  );
};

export default Video;
