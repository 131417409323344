import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PublicService from '../../../Services/PublicService';

function WithPrivateRoute({ Children, ...rest }) {
  const token = localStorage.getItem('token') || null;

  const validUser = () => {
    let valid = false;
    if (token) {
      valid = PublicService.checker(token)
        .then((reponse) => true)
        .catch(() => {
          localStorage.removeItem('token');
          return false;
        });
    } else {
      localStorage.removeItem('token');
      valid = false;
    }

    return valid;
  };

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        validUser() ? (
          Children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: matchProps.location },
            }}
          />
        )
      }
    />
  );
}

export default WithPrivateRoute;
