import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import LoginService from '../../Services/LoginService';
import WithValidToken from '../../common/hoc/WithValidToken';
import PASSWORD_SCHEMA from './PasswordSchema';

const Password = (props) => {
  const history = useHistory();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: '',
      repeat: '',
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, PASSWORD_SCHEMA);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.isValid) {
      return;
    }

    if (formState.values.password !== formState.values.repeat) {
      return;
    }

    const password = formState.values.password;
    await LoginService.changePassword(password).then((response) => {
      console.log(response);
      // history.push('/');
    });
  };

  const handleCancel = (event) => {
    history.push('/');
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Cambiar Contraseña</h1>
      </div>
      <form className="new-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="password">
            <b>Contraseña</b>
          </label>
          <input
            type="password"
            placeholder="Contraseña"
            name="password"
            onChange={handleChange}
            autoComplete="false"
          />
          {hasError('password') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>
        <div className="form-field">
          <label htmlFor="repeat">
            <b>Repetir Contraseña</b>
          </label>
          <input
            type="password"
            placeholder="Repetir Contraseña"
            name="repeat"
            autoComplete="false"
            onChange={handleChange}
          />
          {hasError('repeat') && (
            <span className="field-error">Nombre es requerido</span>
          )}
        </div>
        <div className="buttons-container">
          <div className="cancel-button">
            <button className="button-error" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="save-button">
            <button className="button-success" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default WithValidToken(Password);
