import fetchCall from './ComunicationService';

const LoginService = () => {
  const signIn = (email, password) => {
    const data = { email, password };
    const headers = {
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('users/signin', {}, headers, 'POST', data)
      .then((response) => response)
      .catch((error) => {
        throw new error();
      });
  };

  const changePassword = (password) => {
    const data = { password };
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    return fetchCall
      .http('users/request', {}, headers, 'POST', data)
      .then((response) => response)
      .catch((error) => console.log(error));
  };

  return {
    signIn,
    changePassword,
  };
};

export default LoginService();
