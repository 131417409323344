import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PrivateRoute from '../common/componentes/WithPrivateRoute';
import Main from '../common/Layouts/Main';
import Dashboard from './Dashboard';
import Password from './Password';
import Service from './Service';
import Login from './Login';
import Logout from './Logout';
import Video from './Video';
import Message from './Message';
import Questionary from './Questionary';

const Routes = () => {
  return (
    <Router>
      <Route exact path="/">
        <Login />
      </Route>
      <PrivateRoute exact path="/dashboard">
        <Main>
          <Dashboard />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/questionary/:id">
        <Main>
          <Questionary />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/message/:id">
        <Main>
          <Message />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/change-password">
        <Main>
          <Password />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/servicio/:id">
        <Main>
          <Service />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/video">
        <Main>
          <Video />
        </Main>
      </PrivateRoute>
      <PrivateRoute exact path="/logout">
        <Main>
          <Logout />
        </Main>
      </PrivateRoute>
    </Router>
  );
};

export default Routes;
