import React, { useState, useEffect } from 'react';
import ContractService from '../../Services/ContractServices';
import Cards from '../../common/componentes/Cards';
import WithValidToken from '../../common/hoc/WithValidToken';
import Spinner from '../../common/componentes/Spinner';

function Dashboard() {
  const [contracts, setContracts] = useState([]);
  useEffect(function () {
    ContractService.getContracts()
      .then((contracts) => {
        setContracts(contracts);
      })
      .catch((error) => console.log(error));
  }, []);

  const renderContracts = () => {
    if (contracts.length === 0) {
      return <Spinner />;
    }

    return contracts.map((contract, index) => (
      <Cards
        contractId={contract.contract_id}
        service={contract.service}
        serviceType={contract.service_type}
        allowMessage={contract.allow_messages || false}
        showReservio={contract.show_reservio || false}
        key={index}
      />
    ));
  };
  return <>{renderContracts()}</>;
}

export default WithValidToken(Dashboard);
