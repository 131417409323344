import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror';
import { options, menu } from '@aeaton/react-prosemirror-config-default';
import WithValidToken from '../../common/hoc/WithValidToken';
import ContractService from '../../Services/ContractServices';
import Spinner from '../../common/componentes/Spinner';

function Message(props) {
  const history = useHistory();
  const params = useParams();
  const [messages, setMessages] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [newQuestion, setNewQuestion] = useState(false);
  const [serviceType, setServiceType] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      question: null,
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (history.location?.state?.serviceId) {
      setNewQuestion(history.location.state.allowNewQuestion);
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          serviceId: history.location.state.serviceId,
          serviceName: history.location.state.serviceName,
          contractId: params.id,
        },
      }));
    }

    setServiceType(history.location.state.serviceType);

    ContractService.getContractMessages(params.id)
      .then((messages) => {
        if (messages.nextMessageFrom === 'user') {
          setNewQuestion(true);
        } else {
          setNewQuestion(false);
        }
        setMessages(messages.messages);
      })
      .catch(() => setMessages([]));
  }, []);

  const handleHtmlEditor = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }));
  };

  const renderForm = (event) => {
    let form = null;
    let showForm = false;
    if (serviceType === 'professional') {
      showForm = true;
    }

    if (
      serviceType !== 'professional' &&
      messages &&
      (messages.messages.length === 0 || messages.messages.length === 2)
    ) {
      showForm = true;
    }

    if (
      serviceType !== 'professional' &&
      messages &&
      messages.messages.filter((message) => message.from === 'user').length ===
        1
    ) {
      showForm = true;
    }

    if (
      serviceType !== 'professional' &&
      messages &&
      messages.messages.filter((message) => message.from === 'user').length ===
        2
    ) {
      showForm = false;
    }

    if (showForm) {
      form = (
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <div className="textarea-container">
              {history.location.state.allowNewQuestion && (
                <HtmlEditor
                  name="question"
                  options={options}
                  value={formState.values.question || ''}
                  onChange={(v) => handleHtmlEditor(v, 'question')}
                  render={({ editor, view }) => (
                    <div>
                      <MenuBar menu={menu} view={view} />
                      {editor}
                    </div>
                  )}
                />
              )}
            </div>
          </div>
          {history.location.state.allowNewQuestion && (
            <div className="form-field">
              {disableButton && <Spinner />}
              {!disableButton && (
                <button
                  className="button"
                  onClick={handleChange}
                  disabled={disableButton}
                >
                  Click para enviar dudas
                </button>
              )}
            </div>
          )}
          {/* {!history.location.state.allowNewQuestion && (
            <div className="service-message">
              Si tienes más dudas, ponte en contacto conmigo a través de{' '}
              <a href="mailto:hola@mimatemama.com">hola@mimatemama.com</a> y
              vemos que servicio puede ayudarte. Igualmente puedes ver los
              servicios disponibles haciendo click{' '}
              <a href="https://www.mimatemama.com/servicios">aquí</a>.
            </div>
          )} */}
        </form>
      );
    }

    return form;
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : !event.target.files
            ? event.target.value
            : event.target.files[0],
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.values.question) {
      return;
    }

    setDisableButton(true);

    await ContractService.addContractMessage({
      ...formState.values,
      from: 'user',
      type: 'text',
    }).then((response) => {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          question: null,
        },
      }));

      setDisableButton(false);
      setNewQuestion(false);
      setMessages(response);
    });
  };

  const renderMessageType = (message, index) => {
    let msgHtml;

    if (message.type === 'text') {
      msgHtml = (
        <div
          className={
            message.from === 'admin' ? 'message-admin' : 'message-user'
          }
          key={index}
          dangerouslySetInnerHTML={{
            __html: message.message,
          }}
        ></div>
      );
    } else if (message.type === 'audio') {
      msgHtml = (
        <div className="message-admin audio-message" key={index}>
          <div
            style={{
              backgroundColor: 'var(--white)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <audio controls>
              <source
                src={`https://storage.googleapis.com/mimate-audios/${message.message}`}
                key={index}
                type="audio/mpeg"
                preload="metadata"
              />
            </audio>
          </div>
        </div>
      );
    }

    return msgHtml;
  };

  const renderMessages = () => {
    if (!messages) {
      return;
    }

    if (!messages.messages || messages.messages.length === 0) {
      return;
    }

    return (
      messages.messages &&
      messages.messages.map((message, index) => {
        if (message.message) {
          return renderMessageType(message, index);
        } else {
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            ></div>
          );
        }
      })
    );
  };

  const renderServices = () => {
    if (!messages) {
      return;
    }

    if (serviceType === 'professional') {
      return;
    }

    if (messages.messages.length < 4) {
      return;
    }

    return (
      <div className="service-message">
        Si tienes más dudas, ponte en contacto conmigo a través de{' '}
        <a href="mailto:hola@mimatemama.com">hola@mimatemama.com</a> y vemos que
        servicio puede ayudarte. Igualmente puedes ver los servicios disponibles
        haciendo click <a href="https://www.mimatemama.com/servicios">aquí</a>.
      </div>
    );
  };

  return (
    <div className="services-container">
      <div className="title">
        <h1>Ronda de preguntas: {formState.values.serviceName}</h1>
      </div>
      <div className="form-field">
        <label htmlFor="description">
          {serviceType === 'professional' ? (
            <b>
              Puedes enviarme todas tus dudas aquí, y te contestaré en los
              siguientes 24 horas laborales.
            </b>
          ) : (
            <b>
              Una vez hayas acabado de ver el taller, puedes enviarme todas tus
              dudas aquí (durante los 30 días siguientes a la realización del
              taller), y te contestaré en los siguientes 3 días laborales. Una
              vez acaben los 30 días podrás consultar los mensajes escritos,
              pero ya no se podrán realizar más consultas
            </b>
          )}
        </label>
      </div>
      <div className="message-container">{renderMessages()}</div>
      {renderForm()}
      {renderServices()}
    </div>
  );
}

export default WithValidToken(Message);
