import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import schema from './schema';
import './Login.css';
import LoginService from '../../Services/LoginService';

const Login = () => {
  const history = useHistory();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const currentToken = localStorage.getItem('token') || null;
    if (currentToken) {
      setToken(currentToken);
      history.push('/dashboard');
    }
  }, [history, token]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setError(false);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid) {
      LoginService.signIn(formState.values.email, formState.values.password)
        .then((response) => {
          if (response.token) {
            localStorage.setItem('token', response.token);
            setToken(response.token);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  return (
    <div className="wrapper">
      <div className="box">
        <div className="container">
          <div className="logo-container">
            <img src={logo} alt="mimate mama" className="logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-field">
              <label htmlFor="email">
                <b>Username</b>
              </label>
              <input
                type="text"
                placeholder="correo electronico"
                name="email"
                onChange={handleChange}
              />
              {hasError('email') && (
                <span className="field-error">email invalido</span>
              )}
            </div>
            <div className="form-field">
              <label htmlFor="password">
                <b>Password</b>
              </label>
              <input
                className={
                  formState.touched['password'] && formState.errors['password']
                    ? 'invalid'
                    : ''
                }
                type="password"
                placeholder="correo electronico"
                name="password"
                onChange={handleChange}
              />
            </div>
            {error && (
              <div className="error">
                <span className="field-error">
                  Error en Usuario y/o Contaseña
                </span>
              </div>
            )}
            <div className="buttons-container">
              <button
                type="submit"
                className="button"
                disabled={!formState.isValid}
                onClick={handleSubmit}
              >
                Iniciar Sesión
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
