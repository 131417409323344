import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../../../assets/images/logo.svg';
import './header.css';

const Header = (pops) => {
  const menuRef = useRef();

  const handleMobileMenu = (event) => {
    event.stopPropagation();
    event.currentTarget.classList.toggle('change');
    menuRef.current.classList.toggle('mobile-active');
    menuRef.current.classList.toggle('mobile-inactive');
  };

  return (
    <nav>
      <div className="header-container">
        <div className="mobile-hamburger" onClick={handleMobileMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <Link to="/dashboard" className="desktop-logo">
          <img src={logo} alt="mimate mama" className="logo" />
        </Link>
        <div className="mobile-logo-link">
          <img src={logo} alt="mimate mama" className="logo" />
        </div>
        <div className="mobile-links mobile-inactive" ref={menuRef}>
          <Link to="/dashboard" className="link">
            Mis Servicios
          </Link>
          <Link to="/change-password" className="link">
            Contraseña
          </Link>
          <NavLink to="/logout" className="link">
            Salir
          </NavLink>
        </div>
        <div className="links-container">
          <NavLink
            to="/dashboard"
            className="link"
            activeClassName="link-active"
          >
            Mis Servicios
          </NavLink>
          <NavLink
            to="/change-password"
            className="link"
            activeClassName="link-active"
          >
            Contraseña
          </NavLink>
          <NavLink to="/logout" className="link" activeClassName="link-active">
            Salir
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
