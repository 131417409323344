import React from 'react';
import Header from './Components/Header';
import './main.css';
const Main = (props) => {
  const { children } = props;
  return (
    <div className="main">
      <Header />
      <div className="content">{children}</div>
    </div>
  );
};

export default Main;
