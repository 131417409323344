const PASSWORD_SCHEMA = {
  password: {
    presence: { allowEmpty: false },
  },
  repeat: {
    presence: { allowEmpty: false },
  },
};

export default PASSWORD_SCHEMA;
