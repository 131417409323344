import React, { useRef, useEffect } from 'react';
import './modal.css';

const Modal = (props) => {
  const modalElement = useRef(null);
  const { show, setShowModal, children } = props;

  useEffect(() => {
    if (show) {
      modalElement.current.className += ' show';
    } else {
      modalElement.current.className = 'modal';
    }
  }, [show]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div id="myModal" className="modal" ref={modalElement}>
      <div className="modal-content">
        <button className="close" onClick={handleClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
