import React, { useState } from 'react';
import './notification.css';

const Notification = (props) => {
  const { text, severity, hideNotification } = props;
  const [show, setShow] = useState(true);

  const handleClick = (event) => {
    event.stopPropagation();
    setShow(false);
    hideNotification(false);
  };

  return (
    <>
      {show && (
        <div
          className={`notification-wrapper ${severity}`}
          onClick={handleClick}
        >
          <div>{text}</div>
        </div>
      )}
    </>
  );
};

export default Notification;
