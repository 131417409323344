import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import WithValidToken from '../../common/hoc/WithValidToken';
import ContractService from '../../Services/ContractServices';
import Notification from '../../common/componentes/Notification';
import './questionary.css';

function Questionary(props) {
  const history = useHistory();
  const params = useParams();

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      completed: false,
      questionary: {},
    },
    touched: {},
    errors: {},
    questions: {},
  });

  useEffect(() => {
    if (history.location?.state?.serviceId) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          serviceId: history.location.state.serviceId,
          serviceName: history.location.state.serviceName,
          contractId: history.location.state.contractId,
        },
        questions: {
          ...formState.questions,
          ...history.location.state.questions,
        },
      }));
    }

    // buscar questionario en el back
    ContractService.getContract(params.id).then((response) => {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          questionary: response.questionary,
          completed: response.questionary_completed,
        },
      }));
    });
  }, []);

  const renderTitle = () => {
    if (!formState.questions.questionaryInfo) {
      return;
    }

    return (
      <>
        <div className="questionryTitle">
          <h1>{formState.questions.questionaryInfo.questionaryTitle}</h1>
        </div>
        <div className="introContainer">
          <p>{formState.questions.questionaryInfo.questionaryIntro}</p>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    event.persist();
    console.log(event.target.name);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        questionary: {
          ...formState.values.questionary,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : !event.target.files
              ? event.target.value
              : event.target.type === 'radio'
              ? event.target.value
              : event.target.files[0],
        },
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const renderQuestionField = (question) => {
    let field = null;

    switch (question.questionType) {
      case 'number':
        field = (
          <input
            type="number"
            name={question.questionId}
            onChange={handleChange}
            defaultValue={
              formState.values.questionary &&
              formState.values.questionary[question.questionId]
                ? formState.values.questionary[question.questionId]
                : ''
            }
          />
        );
        break;
      case 'textarea':
      case 'text':
        field = (
          <textarea
            name={question.questionId}
            onChange={handleChange}
            defaultValue={
              formState.values.questionary &&
              formState.values.questionary[question.questionId]
                ? formState.values.questionary[question.questionId]
                : ''
            }
          />
        );
        break;
      case 'radio':
        field = question.options.map((option, key) => (
          <div key={key}>
            <input
              type="radio"
              id={option.optionId}
              name={question.questionId}
              value={option.optionTitle}
              onChange={handleChange}
              checked={
                formState.values.questionary &&
                formState.values.questionary[question.questionId] ===
                  option.optionTitle
                  ? true
                  : false
              }
            />
            <label htmlFor={option.optionId}>{option.optionTitle}</label>
            <br></br>
          </div>
        ));
        break;
      case 'date':
        field = (
          <input
            name={question.questionId}
            type="date"
            onChange={handleChange}
            defaultValue={
              formState.values.questionary &&
              formState.values.questionary[question.questionId]
                ? formState.values.questionary[question.questionId]
                : ''
            }
          />
        );
        break;
      case 'checkbox':
        field = question.options.map((option, key) => (
          <>
            <input
              key={key}
              type="checkbox"
              name={option.optionId}
              onChange={handleChange}
              checked={
                formState.values.questionary &&
                formState.values.questionary[option.optionId]
                  ? true
                  : false
              }
            />
            {option.optionTitle}
            <br />
          </>
        ));
        break;
      default:
        break;
    }

    return field;
  };

  const renderSectionQuestions = (questions) => {
    return questions.map((question, index) => {
      return (
        <h4 className="questionText" key={index}>
          {question.questionTitle}
          <br />
          <br /> {renderQuestionField(question)}
        </h4>
      );
    });
  };

  const renderQuestions = () => {
    if (!formState.questions.sections) {
      return;
    }

    return formState.questions.sections.map((section, index) => {
      return (
        <div className="sections" key={index}>
          <p>{section.sectionTitle}</p>
          {renderSectionQuestions(section.questions)}
        </div>
      );
    });
  };

  const handleFinish = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        questionary_completed: true,
      },
    }));
    ContractService.saveAnswers({
      ...formState.values,
      questionary_completed: true,
    }).then((response) => {
      setSaveSuccess(true);
    });
  };

  const handleSave = (event) => {
    event.preventDefault();
    ContractService.saveAnswers(formState.values).then((response) => {
      setSaveSuccess(true);
    });
  };

  return (
    <div className="services-container">
      {renderTitle()}
      <div className="questionsContainer">
        <form onSubmit={handleSave}>
          {renderQuestions()}
          {!formState.values.completed && (
            <div className="questionary-buttons">
              <br />
              <p>
                Puedes rellenar el cuestionario a tu ritmo, guardando las
                respuestas que vayas completando. Una vez hayas contestado todas
                las preguntas, debes hacer clic en "Finalizar y Enviar". Una vez
                enviado, no se podrá editar las respuestas.
              </p>
              <br />
              <button type="submit" className="button" onClick={handleSave}>
                Guardar y continuar luego
              </button>
              <button
                type="button"
                className="button-success"
                onClick={handleFinish}
              >
                Finalizar y Enviar
              </button>
            </div>
          )}
          {saveSuccess && (
            <Notification
              text="Se ha guardado correctamente tu información"
              severity="success"
              hideNotification={setSaveSuccess}
            />
          )}
        </form>
      </div>
    </div>
  );
}

export default WithValidToken(Questionary);
