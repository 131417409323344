import React from 'react';
import './spinner.css';

const Spinner = (props) => {
  return (
    <div className="spinner-wrapper">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
