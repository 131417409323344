import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PublicService from '../../../Services/PublicService';

function WithValidToken(ComponentBeingWrapped) {
  function WrapperComponent(props) {
    const token = localStorage.getItem('token') || null;
    const history = useHistory();

    useEffect(() => {
      if (token) {
        PublicService.checker(token)
          .then(() => {
            if (history.location.pathname === '/') {
              history.push('/dashboard');
            }
          })
          .catch(() => {
            localStorage.removeItem('token');
            history.push('/');
            return;
          });
      } else {
        history.push('/');
      }
    }, [history, token]);

    return <ComponentBeingWrapped {...props} />;
  }

  return WrapperComponent;
}

export default WithValidToken;
